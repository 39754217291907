import CryptoJS from 'crypto-js'
import { CRYPTO_SECRET_KEY, STATUS_LIST } from 'config/config'
import { SUBSCRIPTION_TYPES } from 'config/config';
import { PRODUCT_TYPES } from 'config/config';
export const encryptText = (message) => {
    message = message.toString();
    const cipherText = CryptoJS.AES.encrypt(message, CRYPTO_SECRET_KEY);
    return encodeURIComponent(cipherText.toString());
}
export const decryptText = (message) => {
    var bytes = CryptoJS.AES.decrypt(decodeURIComponent(message), CRYPTO_SECRET_KEY)
    return bytes.toString(CryptoJS.enc.Utf8)
}
export const encryptObject = (obj) => {
  obj = JSON.stringify(obj);
  return CryptoJS.AES.encrypt(obj, CRYPTO_SECRET_KEY).toString();
}
export const decryptObject = (obj) => {
  return JSON.parse(CryptoJS.AES.decrypt(obj, CRYPTO_SECRET_KEY).toString(CryptoJS.enc.Utf8));
}
export const getQueryParams = (url) => {
  if(url.indexOf('?') == -1){
    return {};
  }
  const paramArr = url.slice(url.indexOf('?') + 1).split('&');
  const params = {};
  paramArr.map(param => {
      const [key, val] = param.split('=');
      var queryval = val.replace(/\+/g, '%20');
      params[key] = decodeURIComponent(queryval);
  })
  return params;
}
export const placeParams = (pathRegex, params) => {
  const segments = pathRegex.split("/");
  return (
    segments
    .map((segment) => {
      let offset = segment.indexOf(":?") + 1;

      if (offset) {
        let key = segment.slice(offset + 1);
        return params[key];
      }

      offset = segment.indexOf(":") + 1;
      if (!offset) return segment;

      let key = segment.slice(offset);
      return params[key];
    })
    .join("/")
    // Remove trailing "/"
    .replace(/\/+$/, "")
    // Remove redundant "/"
    .replace(/\/{2,}/, "/")
  );
}

export const generateRandomNumber = () => parseInt(Date.now() * Math.random());

export const capitalizeWord = (text) => {
  const words = text.split(" ");

  for (let i = 0; i < words.length; i++) {
    if(words[i][0]){
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
  }
  return words.join(" ");;
}

export const scrollToTop = () => {
  // called for pagination and filter
  if(!document.querySelector('.has-filter') || (document.querySelector('.has-filter') && document.documentElement.scrollTop > 300)){
    document.documentElement.scrollTo(0, 0);
    if(document.querySelector('.list-section.overflow-scroll-section')){
      document.querySelector('.list-section.overflow-scroll-section').scrollTop = 0;
    }
  }
}
export const routeScrollToTop = () => {
  // called on route change
  window.$('html, body').animate({scrollTop:0}, '300');
}

export const numberFormatShort = ( numberVal, precision = 0) =>
{
  let n_format="";
  let suffix = 'T';
  if (numberVal < 999) {
      // 0 - 999
      n_format = numberVal.toFixed(precision);
      suffix = '';
  } else if (numberVal < 900000) {
      // 0.9k-850k
      n_format = (numberVal / 1000).toFixed(precision);
      suffix = 'K';
  } else if (numberVal < 900000000) {
      // 0.9m-850m
      n_format = (numberVal / 1000000).toFixed(precision);
      suffix = 'M';
  } else if (numberVal < 900000000000) {
      // 0.9b-850b
      n_format = (numberVal / 1000000000).toFixed(precision);
      suffix = 'B';
  } else {
      // 0.9t+
      n_format = (numberVal / 1000000000000).toFixed(precision);
      suffix = 'T';
  }
  return n_format + suffix;
}
export const checkActiveRoute = (activeRoute,
  routeList,
  haveChild = false,
  matchExactRoute = false
) => {
  var className = "";
  if (!matchExactRoute) {
    const paths = activeRoute.split("/");
    className =
      paths.some((path) => routeList.includes(path)) ||
      activeRoute.includes(routeList[0])
        ? "active"
        : "";
  } else {
    className = routeList.every((e) => e.includes(activeRoute)) ? "active" : "";
  }
  if (haveChild && className == "active") {
    className += " show";
  }
  return className;
};
export const getValidURL = (url) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'https://' + url;
  }
  return url;
}
export const getStatusNameById = (id) => {
  var selectedSlug ="-";
  const filteredData = STATUS_LIST.filter(function (item) {
    return item.id == id;
  });
  if(filteredData.length > 0){
    selectedSlug = filteredData[0].name;
  }
  return selectedSlug;
}
export const getSubscriptionTypeObjBySlug = (slug) => {
  var selectedSlug =null;
  const filteredData = SUBSCRIPTION_TYPES.filter(function (item) {
    return item.slug == slug;
  });
  if(filteredData.length > 0){
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
}
export const getProductTypeObjBySlug = (slug) => {
  var selectedSlug =null;
  const filteredData = PRODUCT_TYPES.filter(function (item) {
    return item.slug == slug;
  });
  if(filteredData.length > 0){
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
}

export const  arraysAreEqual = (arr1, arr2) => {
  // Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Sort the arrays so that elements are in the same order
  arr1.sort();
  arr2.sort();

  // Compare each element of the arrays
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  // If all elements match, the arrays are similar
  return true;
}