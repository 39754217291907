import { userInfoSelector } from "components/_selectors/authSelectors";
import { activePageInfoSelector } from "components/_selectors/settingSelectors";
import { loginRoutes } from "config/routing";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logoutUser } from "store/actions/AuthAction";
import { getAwsUrl } from "utils/AwsHelper";
import { capitalizeWord } from "utils/CommonHelper";
import { confirmAlert } from "utils/SwalAlertHelper";
import { DefaultProfileImage } from "components/_common/Icons";
import { mainRoutes as routes } from "config/routing";
// import { Logo1, Logo2, Logo3 } from "components/_common/Icons";
import { Logos } from "components/_common/Icons";
import NavbarLinks from "components/_common/Header/NavbarLinks";
import { getUserHomePage } from "config/routing";

function Header(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userInfo, activePage } = props;
  if (userInfo && Object.keys(userInfo).length > 0) {
    return (
      <header className="p-3 mb-3 border-bottom">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
              <li>
                <Link
                  to={getUserHomePage()}
                  className="nav-link px-2 link-secondary"
                >
                  <img
                    src={Logos}
                    style={{ width: "410px" }}
                    alt="logo1"
                    className="me-3"
                  />
                </Link>
              </li>
              {/* <li>
                <a href="#" className="nav-link px-2 link-secondary">
                  <img src={Logo1} alt="logo1" className="me-3" />
                </a>
              </li>
              <li>
                <a href="#" className="nav-link px-2 link-body-emphasis">
                  <img src={Logo2} alt="logo2" className="me-3" />
                </a>
              </li>
              <li>
                <a href="#" className="nav-link px-2 link-body-emphasis">
                  <img src={Logo3} alt="logo3" />
                </a>
              </li> */}
            </ul>
            <div className="dropdown top_dropdown1 text-end">
              <a
                href="#"
                className="d-block link-body-emphasis text-decoration-none dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={
                    userInfo.photo
                      ? getAwsUrl(userInfo.photo)
                      : DefaultProfileImage
                  }
                  alt="Profile"
                  loading="lazy"
                  width={32}
                  height={32}
                  className="rounded-circle"
                />
              </a>
              <ul className="dropdown-menu text-small rounded-0">
                <NavbarLinks userInfo={userInfo} />
              </ul>
            </div>
          </div>
        </div>
      </header>
    );
  } else {
    return null;
  }
}
const mapStateToProps = (state) => {
  return {
    activePage: activePageInfoSelector(state),
    userInfo: userInfoSelector(state),
  };
};
export default connect(mapStateToProps, {})(Header);
