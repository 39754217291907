import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
export default function Error404() {
  const navigate = useNavigate();
  const {t} = useTranslation();
  return (
    <div id="content" className="p-5">
      <div className="text-center">
          <div className="error mx-auto">404</div>
          <p className="lead text-gray-800 mb-5">{ t('page_not_found') }</p>
          <Link className="btn tft_btn" onClick={(e) => {e.preventDefault();navigate(-1)}}>&larr; { t('go_back') }</Link>
      </div>
    </div>
  );
}
