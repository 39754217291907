import { t } from "i18next";
import Swal from "sweetalert2";
export const confirmAlertOld = (msg, callback) => {
    Swal.fire({
        title: t("confirm_title"),
        text: msg,
        icon: 'warning',
        showCancelButton: !0,
        confirmButtonText: t("yes"),
        cancelButtonText: t("no"),
        customClass: {
          confirmButton: 'btn tft_btn',
          cancelButton: 'btn tft1_btn me-2'
        },
        buttonsStyling: !1,
      }).then(function (result) {
        if (result.isConfirmed) {
          if(callback){
              callback();
          }
        }
      });
}

export const confirmAlert = (msg, callback) => {
  Swal.fire({
      // title: t("confirm_title"),
      text: msg,
      // text: "Are you sure you want to make the changes? Please confirm.",
      // icon: 'warning',
      showCancelButton: !0,
      confirmButtonText: t("confirm"),
      cancelButtonText: t("cancel"),
      reverseButtons: true,
      customClass: {
        container: 'swal-custom-confirm-container',
        popup: 'swal-custom-confirm-popup',
        htmlContainer: 'swal-custom-confirm-html-container',
        actions:'pt-3',
        confirmButton: 'btn tft_btn',
        cancelButton: 'btn tft1_btn me-2'
      },
      /* showClass: {
        popup: 'animate__animated faster animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated faster animate__fadeOutUp'
      }, */
      /* showClass: {
        popup: 'swal2-show',
        backdrop: 'swal2-backdrop-show',
        icon: 'swal2-icon-show'
      },
      hideClass:{
        popup: 'swal2-hide',
        backdrop: 'swal2-backdrop-hide',
        icon: 'swal2-icon-hide'
      }, */
      buttonsStyling: !1,
    }).then(function (result) {
      if (result.isConfirmed) {
        if(callback){
            callback();
        }
      }
    });
}
export const messageAlert = (msg="", callback) =>{
  Swal.fire({
    icon: 'success',
    confirmButtonText: 'Continue',
    // html: '<p class="fnw_700 fs26" style="font-size: 25px;line-height:37.5px;">'+((msg)?msg:'Your changes have been <span class="mytextgreen">successfully</span> saved')+'</p>',
    html: '<p class="fnw_700 fs26" style="font-size: 25px;line-height:37.5px;">'+((msg)?msg:t("changes_saved_success_msg"))+'</p>',
    iconColor: "#6fcf97",
    // iconHtml: '<p class="mytextgreen" style="font-size: 120px;"><i class="bi bi-check-circle"></i></p>',
    customClass: {
      container: 'swal-custom-msg-container',
      icon: 'swal-custom-msg-icon mb-5',
      popup: 'swal-custom-msg-popup',
      htmlContainer: 'swal-custom-msg-html-container',
      actions:'pt-2 mb-5',
      confirmButton: 'btn tft_btn',
    },
  }).then(function (result) {
    if (result.isConfirmed) {
      if(callback){
        callback();
      }
    }
  });
}