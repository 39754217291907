import moment from 'moment';
import store from 'store';
import { settingsSelector } from 'components/_selectors/authSelectors';

export const getCurrentDateTime = () => {
  return moment();
}
export const getFormattedDate = (date, dateFormat="") => {
  // if(settingsSelector(store.getState()).date_format_web){
  //   dateFormat = settingsSelector(store.getState()).date_format_web;
  //   dateFormat = dateFormat.split(" ")[0];
  // }
  if(dateFormat==""){
    dateFormat='DD/MM/YYYY'
  }
  return moment(date).format(dateFormat);
}
export const getFormattedTime = (dateTime, timeFormat='h:mm A') => {
  //  var date = moment().format('YYYY-MM-DD')+" "+time;
  return moment(dateTime).format(timeFormat);
}
export const getFormattedDateTime = (date, dateTimeFormat="") => {
  if(dateTimeFormat==""){
    dateTimeFormat='DD/MM/YYYY h:mm A'
  }
  return moment(date).format(dateTimeFormat);
}
export const getUTCStartDateTime = (date, dateTimeFormat="") => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  const utcDatetime = moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
  return utcDatetime;
}
export const getUTCEndDateTime = (date, dateTimeFormat="") => {
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(0);
  const utcDatetime = moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
  return utcDatetime;
}
export const getServerFormattedDate = (date) => {
  var dateFormat = "YYYY-MM-DD";
  return moment(date).format(dateFormat);
};
export const convertMsToSeconds = (milliseconds) => {
  const seconds = moment.duration(milliseconds).asSeconds();
  return seconds;
}
export const getExportDataDateRange = (type) => {
  var dateRange = {};
  switch (type) {
    case "yesterday":
      dateRange = {
        from_date: (moment().subtract(1, 'days')),
        to_date: moment().subtract(1, 'days')
      };
      break;
    case "this_week":
      dateRange = {
        from_date: moment().startOf('week'),
        to_date: moment().endOf('week')
      };
      break;
    case "this_month":
      dateRange = {
        from_date: moment().startOf('month'),
        to_date: moment().endOf('month')
      };
      break;
    case "this_year":
      dateRange = {
        from_date: moment().startOf('year'),
        to_date: moment().endOf('year')
      };
      break;
    case "last_year":
      dateRange = {
        from_date: moment().subtract(1, 'year').startOf('year'),
        to_date: moment().subtract(1, 'year').endOf('year')
      };
      break;
    case "today":
      dateRange = {
        from_date: moment(),
        to_date: moment()
      };
      break;
    default:
      dateRange=null;
      break;
  }
  return dateRange;
}