import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();
  /* return (
    <section className="footer fixed-bottom">
      <div className="container">
        <div className="row">
          <div className="d-flex font_color_grey">
            <div className="pe-2">• </div>
            <div>
              <p className="mb-0 fnw_500" style={{ fontSize: 14 }}>
                A list view of all the participating schools is available here.
              </p>
              <p className="fnw_700" style={{ fontSize: 12 }}>
                {t("need_assistence?")} {t("need_assistence_msg")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  ); */
  return null;
}
