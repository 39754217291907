import store from "store";
import api from "config/api";
import { getUserInfo, storeUserInfo } from "utils/StorageHelper";
import { manageAPICall } from "./CommonCrudAction";
import { userInfoSelector } from "components/_selectors/authSelectors";
import { successMessage, errorMessage } from "utils/ToastNotificationHelper";
import { removeLocalStorage } from "utils/StorageHelper";
import apiService from "services/api.service";

export const loginUser = async (data, callback) => {
  manageAPICall(api.login.url, api.login.method, data, callback);
};
export const forgotPasswordCall = async (data, callback) => {
  manageAPICall(api.forgotPassword.url, api.forgotPassword.method, data, callback);
};
export const resendOTPAPI = async (data, callback) => {
  const response = await apiService.getPublicTokenData(
    api.resendOTP.url,
    data,
    data.authToken
  );
  if (response) {
    callback(response.status, response);
  }
};
export const verifyOTPAPI = async (data, callback) => {
  const response = await apiService.submitPublicTokenData(
    api.verifyOTP.url,
    data,
    data.authToken
  );
  if (response) {
    callback(response.status, response);
  }
};
export const updateProfile = async (data, callback) => {
  manageAPICall(api.updateProfileDetails.url, api.updateProfileDetails.method, data, callback);
};
export const getProfileDetails = async (data, callback) => {
  manageAPICall(api.getProfileDetails.url, api.getProfileDetails.method, data, callback);
};
export const resetPasswordAPI = async (data, callback) => {
  const response = await apiService.submitPublicTokenData(
    api.resetPassword.url,
    data,
    data.token
  );
  if (response) {
    callback(response.status, response);
  }
};
export const logoutUser = async (callback) => {
  // var userInfo = userInfoSelector(store.getState());
  // var deviceId = userInfo?.device?.device_id;
  // if (deviceId) {
    // var data = {
    //   device_id: deviceId,
    // };
    manageAPICall(api.logout.url,api.logout.method, {}, (status, response) => {
      if (response) {
        if (response.status) {
          successMessage("You have logged out successfully");
          removeLocalStorage("userInfo");
          store.dispatch({
            type: "UPDATE_USER_DETAILS",
            payload: {},
          });
          callback();
        } else {
          errorMessage(response.message);
        }
      }
    })
  // } else {
  //   removeLocalStorage("userInfo");
  //   store.dispatch({
  //     type: "UPDATE_USER_DETAILS",
  //     payload: {},
  //   });
  //   callback();
  // }
};
export const refreshToken = async (callback) => {
  var userInfo = userInfoSelector(store.getState());
  var data = {
    refresh_token: userInfo.refresh_token,
  };
  manageAPICall(api.refreshToken.url, data, (status, response) => {
    // console.log(response);
    if (response) {
      if (status) {
        var obj = getUserInfo();
        obj.token = response.data?.token;
        storeUserInfo(obj);
        callback();
      } else {
        console.log("error");
      }
    }
  });
};
