import React from "react";
import { mainRoutes as routes, loginRoutes } from "config/routing";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { checkActiveRoute } from "utils/CommonHelper";
import { useTranslation } from "react-i18next";
import { capitalizeWord } from "utils/CommonHelper";
import { confirmAlert } from "utils/SwalAlertHelper";
import { logoutUser } from "store/actions/AuthAction";

export default function NavbarLinks() {
  const location = useLocation();
  const { t } = useTranslation();
  let pathname = location.pathname;
  const navigate = useNavigate();
  const confirmLogout = () => {
    // delete confirmation
    confirmAlert(t("confirm_logout_msg"), () => {
      // confirmed yes
      logoutUser(() => navigate(loginRoutes.signin.path));
    });
  };
  return (
    <>
      <li>
        <Link
          to={routes.repository.path}
          className={`dropdown-item ${checkActiveRoute(
            pathname,
            [routes.repository.path],
            false,
            true
          )}`}
        >
          <i className="bi bi-unlock-fill" /> {t("repository")}
        </Link>
      </li>
      <li>
        <Link
          to={routes.apiLogs.path}
          className={`dropdown-item ${checkActiveRoute(
            pathname,
            [routes.apiLogs.path],
            false,
            true
          )}`}
          href="#"
        >
          <i className="bi bi-gear-fill" /> <span>{t("activity_logs")}</span>{" "}
          {/* <i className="float-end bi bi-chevron-right" /> */}
        </Link>
      </li>
      <li>
        <Link
          to={routes.changeRequests.path}
          className={`dropdown-item ${checkActiveRoute(
            pathname,
            [routes.changeRequests.path],
            false,
            true
          )}`}
          href="#"
        >
          <i className="bi bi-telephone-fill" />{" "}
          <span>{t("change_requests")}</span>{" "}
          {/* <i className="float-end bi bi-chevron-right" /> */}
        </Link>
      </li>
      <li>
        <Link
          to={routes.users.path}
          className={`dropdown-item ${checkActiveRoute(
            pathname,
            [routes.users.path],
            false,
            true
          )}`}
          href="#"
        >
          <i className="bi bi-person-circle" /> <span>{t("users")}</span>{" "}
          {/* <i className="float-end bi bi-chevron-right" /> */}
        </Link>
      </li>
      <li>
        <Link to={routes.updateProfile.path} className="dropdown-item" href="#">
          <i className="bi bi-pencil-square" /> {t("edit_admin_profile")}
        </Link>
      </li>
      <li>
        <Link to={routes.otpMaster.path} className="dropdown-item" href="#">
          <i class="bi bi-chat-right"></i> {t("otp_master")}
        </Link>
      </li>
      <li>
        <a className="dropdown-item" onClick={() => confirmLogout()}>
          <i className="bi bi-power" /> <span>{t("signout")}</span>{" "}
          {/* <i className="float-end bi bi-chevron-right" /> */}
        </a>
      </li>
    </>
  );
}
