const initialstate = {
  appSettings:{},
  activePageInfo: {
    'title': ''
  }
};

const SettingReducer = (state = initialstate, action) => {
  switch (action.type) {
    case "UPDATE_ACTIVE_PAGE_DETAILS":
      return {
        ...state,
        activePageInfo: action.payload
      };
    case "UPDATE_SETTINGS":
    return {
        ...state,
        appSettings: action.payload
    };
    default:
      return state;
  }
};

export default SettingReducer;
