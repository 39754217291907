import React from "react";
import { mainRoutes as routes, loginRoutes } from "config/routing";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { confirmAlert } from "utils/SwalAlertHelper";
import { logoutUser } from "store/actions/AuthAction";
import { AppBlackLogo } from "components/_common/Icons";
import { userInfoSelector } from "components/_selectors/authSelectors";
import { connect } from "react-redux";
import { getAwsUrl } from "utils/AwsHelper";
import { DefaultProfileImage } from "components/_common/Icons";
import NavbarLinks from "components/_common/Header/NavbarLinks";

function Sidebar(props) {
  const { userInfo } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const confirmLogout = () => {
    // delete confirmation
    confirmAlert(t("confirm_logout_msg"), () => {
      // confirmed yes
      logoutUser(() => navigate(loginRoutes.signin.path));
    });
  };
  // if (userInfo && Object.keys(userInfo).length > 0) {
  //   return (
  //     <nav
  //       className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white"
  //       id="sidenav-main"
  //     >
  //       <div className="container-fluid">
  //         {/* Toggler */}
  //         <button
  //           className="navbar-toggler"
  //           type="button"
  //           data-toggle="collapse"
  //           data-target="#sidenav-collapse-main"
  //           aria-controls="sidenav-main"
  //           aria-expanded="false"
  //           aria-label="Toggle navigation"
  //         >
  //           <span className="navbar-toggler-icon" />
  //         </button>
  //         {/* Brand */}
  //         <Link className="navbar-brand pt-0" to={routes.dashboard.path}>
  //           <img src={AppBlackLogo} className="navbar-brand-img" alt="..." />
  //         </Link>
  //         {/* User Account menu for mobile screen*/}
  //         <ul className="nav align-items-center d-md-none">
  //           <li className="nav-item dropdown d-none">
  //             <a
  //               className="nav-link nav-link-icon"
  //               href="#"
  //               role="button"
  //               data-toggle="dropdown"
  //               aria-haspopup="true"
  //               aria-expanded="false"
  //             >
  //               <i className="ni ni-bell-55" />
  //             </a>
  //             <div
  //               className="dropdown-menu dropdown-menu-arrow dropdown-menu-right"
  //               aria-labelledby="navbar-default_dropdown_1"
  //             >
  //               <a className="dropdown-item" href="#">
  //                 Action
  //               </a>
  //               <a className="dropdown-item" href="#">
  //                 Another action
  //               </a>
  //               <div className="dropdown-divider" />
  //               <a className="dropdown-item" href="#">
  //                 Something else here
  //               </a>
  //             </div>
  //           </li>
  //           <li className="nav-item dropdown">
  //             <a
  //               className="nav-link"
  //               href="#"
  //               role="button"
  //               data-toggle="dropdown"
  //               aria-haspopup="true"
  //               aria-expanded="false"
  //             >
  //               <div className="media align-items-center">
  //                 <span className="avatar avatar-sm rounded-circle">
  //                   <img
  //                     className="profile-img "
  //                     alt="Profile"
  //                     src={(userInfo.photo)? getAwsUrl(userInfo.photo):DefaultProfileImage}
  //                   />
  //                 </span>
  //               </div>
  //             </a>
  //             <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-right">
  //               <div className=" dropdown-header noti-title">
  //                 <h6 className="text-overflow m-0">{t("welcome_msg")}</h6>
  //               </div>
  //               <Link to={routes.updateProfile.path} className="dropdown-item">
  //                 <i className="ni ni-single-02" />
  //                 <span>{t("my_profile")}</span>
  //               </Link>
  //               <div className="dropdown-divider" />
  //               <a onClick={() => confirmLogout()} className="dropdown-item">
  //                 <i className="ni ni-user-run" />
  //                 <span>{t("logout")}</span>
  //               </a>
  //             </div>
  //           </li>
  //         </ul>
  //         {/* Collapse */}
  //         <div className="collapse navbar-collapse" id="sidenav-collapse-main">
  //           {/* Collapse header */}
  //           <div className="navbar-collapse-header d-md-none">
  //             <div className="row">
  //               <div className="col-6 collapse-brand">
  //                 <Link to={routes.dashboard.path}>
  //                   <img alt="logo" src={AppBlackLogo} />
  //                 </Link>
  //               </div>
  //               <div className="col-6 collapse-close">
  //                 <button
  //                   type="button"
  //                   className="navbar-toggler"
  //                   data-toggle="collapse"
  //                   data-target="#sidenav-collapse-main"
  //                   aria-controls="sidenav-main"
  //                   aria-expanded="false"
  //                   aria-label="Toggle sidenav"
  //                 >
  //                   <span />
  //                   <span />
  //                 </button>
  //               </div>
  //             </div>
  //           </div>
  //           {/* Navigation */}
  //           <NavbarLinks />
  //         </div>
  //       </div>
  //     </nav>
  //   );
  // }
  // else{
  //   return null;
  // }
  return null;
}
const mapStateToProps = (state) => {
  return {
    userInfo: userInfoSelector(state),
  };
};
export default connect(mapStateToProps, {})(Sidebar);
