import React from "react";
import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { mainRoutes as routes } from "config/routing";
import PublicRoute from "routes/PublicRoute";
import { AppSignInLogo } from "components/_common/Icons";
import { useTranslation } from "react-i18next";
import { getFormattedDate } from "utils/DateHelper";

export default function SigninLayout(props) {
  const { t } = useTranslation();
  return (
    <PublicRoute>
      <section className="vh-100 gradient-custom my_login my_input_styl1 main-layout">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
              <div className="text-center my_login_box">
                <div className="py-5 px-3">
                  <div className="pb-4">
                    <a href="#">
                      <img src={AppSignInLogo} alt="logo" />
                    </a>
                  </div>
                  <Outlet/>
                  <div className="font_color_grey">
                    <p className="mb-0 fnw_700">{t("need_assistence?")}</p>
                    <p className="fnw_500">
                      {t("need_assistence_msg")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PublicRoute>
  );
}
