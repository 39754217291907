import store from 'store';
import APIService from "services/api.service";
import api from "config/api";
import { successMessage, errorMessage } from 'utils/ToastNotificationHelper';
import { getUserInfo, storeUserInfo, removeLocalStorage } from 'utils/StorageHelper';
import { useTranslation } from 'react-i18next';
export const getListData = async (apiurl, method, data, callback) => {
  // const response = await APIService.callAPI(apiurl, method, data);
  // if(response){
	//   callback(response.status,response);
  // }
  const response = await APIService.getData(apiurl, data);
  if(response){
	  callback(response.status,response);
  }
};
export const manageAPICall = async (apiurl, method, data, callback) => {
  var response;
  if(method == "GET"){
    response = await APIService.getData(apiurl, data);
  }
  else if(method == "POST"){
    response = await APIService.submitData(apiurl, data);
  }
  else if(method == "PATCH"){
    response = await APIService.submitPatchData(apiurl, data);
  }
  else if(method == "DELETE"){
    response = await APIService.deleteData(apiurl, data);
  }
  if(response){
    if(callback){
      callback(response.status,response);
    }
	else{
      return response;
    }
  }
};
export const schoolList = async (params, callback) => {
  var payload = {
      ...params,...{sort: {name: "asc"}}
    };
  const response = await manageAPICall(api.schoolList.url, api.schoolList.method, payload);
  if(response && response.status){
    callback(response.data);
  }
};
export const rolewiseSchoolList = async (params, callback) => {
  var payload = {
      ...params,...{sort: {name: "asc"}}
    };
  const response = await manageAPICall(api.rolewiseSchoolList.url, api.rolewiseSchoolList.method, payload);
  if(response && response.status){
    callback(response.data);
  }
};
export const districtList = async (params, callback) => {
  var payload = {
      ...params,...{sort: {name: "asc"}}
    };
  const response = await manageAPICall(api.districtList.url, api.districtList.method, payload);
  if(response && response.status){
    callback(response.data);
  }
};