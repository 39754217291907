import React, { lazy } from "react";
// signin pages=======
const LoginPage = lazy(() => import("pages/Login"));
const ForgotPasswordPage = lazy(() => import("pages/Login/ForgotPassword"));
const ResetPasswordPage = lazy(() => import("pages/Login/ResetPassword"));
// admin routes======
// dashboard
const DashboardPage = lazy(() => import("pages/Dashboard"));
// Api Log
const ApiLogListPage = lazy(() => import("pages/ApiLog"));
// change request
const ChangeRequestListPage = lazy(() => import("pages/ChangeRequest"));
// Users
const UserListPage = lazy(() => import("pages/User"));
const AddUserPage = lazy(() => import("pages/User/AddUser"));
const EditUserPage = lazy(() => import("pages/User/EditUser"));
const EditUserHistoryPage = lazy(() => import("pages/User/EditUserHistory"));
// repository
const RepositoryListPage = lazy(() => import("pages/Repository"));
const RepositoryActivityListPage = lazy(() =>
  import("pages/Repository/RepositoryActivity")
);
//update profile
const UpdateProfilePage = lazy(() => import("pages/Profile/UpdateProfile"));
// OTP Master
const OtpMasterPage = lazy(() => import("pages/OtpMaster"));
export const mainRoutes = {
  // dashboard
  dashboard: { path: "/", element: <DashboardPage /> },
  // apiLogs
  apiLogs: { path: "/activity-logs", element: <ApiLogListPage /> },
  // change request
  changeRequests: {
    path: "/change-requests",
    element: <ChangeRequestListPage />,
  },
  // user
  users: { path: "/users", element: <UserListPage /> },
  addUser: { path: "/users/add", element: <AddUserPage /> },
  editUser: { path: "/users/edit/:id", element: <EditUserPage /> },
  showEditUserHistory: {
    path: "/users/show-edit-history/:id",
    element: <EditUserHistoryPage />,
  },
  // repository
  repository: { path: "/repository", element: <RepositoryListPage /> },
  repositoryActivity: {
    path: "/repository/:id/activities",
    element: <RepositoryActivityListPage />,
  },
  // otp master
  otpMaster: { path: "/otp-master", element: <OtpMasterPage /> },
  // profile
  updateProfile: { path: "/update-profile", element: <UpdateProfilePage /> },
};

export const loginRoutes = {
  signin: { path: "/signin", element: <LoginPage /> },
  forgotPassword: { path: "/forgot-password", element: <ForgotPasswordPage /> },
  resetPassword: { path: "/reset-password", element: <ResetPasswordPage /> },
};

export const getUserHomePage = () => {
  return mainRoutes.repository.path;
};
